/**
 * Avast form JS
 */

//$_ refers to jQuery added into Text file (WEBSITE-3647)

$(initAvastForm);

function initAvastForm( callback ) {
	var detectIE = avastGlobals.detect.browser.isInternetExplorer;
	// Set page URL for tracking purposes
	$('#AVform [name*="url"]').attr('value', window.location.href);

	$('form.avast-form input[data-set-hide]').each(function() {
        $(this).val($(this).data('set-hide')).closest('div.form-group').hide();
    });

    if (typeof formSubmitTracking !== 'undefined') {
        avastFormSubmitTracking(formSubmitTracking);
    }

	//patch IE placeholders
	if (detectIE === true) {
		function getPlaceHolder(el) {
			return el.getAttribute('placeholder') || $(el).attr('placeholder');
		}

		function setPlaceHolder(el) {
			el.value = getPlaceHolder(el);
		}

		function removePlaceHolder(el) {
			el.value = el.value.replace(getPlaceHolder(el), '');
		}

		var phs = $('input[placeholder]');

		if (document.documentMode < 10) {
			phs.each(function (i, el) {
				if (el.value.length == 0) {
					setPlaceHolder(el);
				}
			});
		}

		phs.on('click focus blur', function () {
			if (this.value.length == 0) {
				setPlaceHolder(this);
			}
		});

		phs.on('keydown', function () {
			removePlaceHolder(this);
		});

		phs.on('keyup', function (e) {
			var k = e.keyCode;
			if (this.value.length == 0 && k != 8 && k != 46) {
				setPlaceHolder(this);
			}
		});
	}

	// focus the first input
/* 	if ($('form.avast-form div.form-group.alert').length) {
		document.querySelectorAll('form.avast-form div.form-group.alert input[type=text]:not(.nofocus)').forEach(function(el) {
			if (el.closest('.form-group').style.display !== 'none') {
				el.focus()
			}
		})
	}
	else {
		document.querySelectorAll('form.avast-form input[type=text]:not(.nofocus)').forEach(function(el) {
			if (el.closest('.form-group').style.display !== 'none') {
				el.focus()
			}
		})
	} */

	// disable html 5 validation
	// try/catch because of IE 7
	try {
		$('form.avast-form').attr('novalidate', 'novalidate');
	} catch (e) {
	}

	// hide elements with display conditions
	$('form.avast-form.live-validation').each(function () {
		var avastForm = new AvastForm(this);
		avastForm.hideConditionInputs();
	});

	// add help hints next to inputs
	$('form.avast-form input[data-help]').each(function () {
		var avastForm = new AvastForm(this);
		avastForm.addHelp(this);
	});

	// start tracking
	$('form.avast-form').on('change', 'input', function (e) {
		if (!$(this.form).hasClass('tracking-started')) {
			var formName = this.form.id.split('\\');
			avastFormStartTracking(formName.pop());
			$(this.form).addClass('tracking-started');
		}
	});

	// add live onchange validation
	$('form.avast-form.live-validation').on('change keyup click', 'input', function (e) {
		if (e.type == 'change') {
			$(this).data('changed', 1);
		}
		if ($(this).data('changed') || $(this.form).data('submitted')) {
			var avastForm = new AvastForm(this.form);
			avastForm.validate(this);
		}
	});

	// add onsubmit validation (unbind first - can be called more times)
	$('form.avast-form.live-validation').off('submit').on('submit', function () {
		$(this).data('submitted', true);
		var avastForm = new AvastForm(this);
		var isValid = avastForm.validate();
		var isAjax = $(this).hasClass('ajax-form');

		if (isValid && isAjax) {
			return avastForm.ajaxSubmit();
		}
		var formDiv = $(this).closest('div.AVsizer');
		if (!isValid && formDiv.length) {
			var offset = parseInt(formDiv.offset().top);
			if (parseInt($(window).scrollTop()) > offset) {
				$('html,body').animate({scrollTop: offset}, 200);
			}
		}
		return isValid;
	});

	// add onChange event for any [data-ajax=] tag to process ajax call for new data and destination tag update
	$('form.avast-form [data-ajax]')
		.on('change', function () {
			var avastForm = new AvastForm(this);
			avastForm.ajaxUpdate($(this));
		});

	if ($.datepicker) {
		$('input.datepicker').datepicker({
			dateFormat: 'dd.mm.yy',
			changeYear: true,
			changeMonth: true
		});
	}

	if( typeof callback === 'function' ){
		callback.call();
	}
}

/**
 * AvastForm
 */
function AvastForm(form) {
	this.form = form;
	if ($(this.globalErrorSelector).length) {
		$(this.form).data('submitted', true);
	}
}

AvastForm.prototype =
{

	trErrorClassName: 'alert',
	helpDivSelector: 'div.bundle-popup',
	errorSelector: 'ul.errors',
	formDivSelector: 'div.AVsizer',
	globalErrorSelector: 'div.bubble.error',
	globalNoErrorSelector: 'div.form-top',
	globalErrorClassName: 'bubble error',
	globalNoErrorClassName: 'form-top',

	ajaxHandlerInput: '_ajax_handler_',
	ajaxErrorInput: '_ajax_error_',
	globalErrorInput: '_global_error_',


	/**
	 * validate whole form or one element
	 * @return  bool
	 */
	validate: function (el) {
		var that = this;
		var validationError = false;
		el = el || 'input';

		$(el, this.form).each(function () {

			// if is radio, test the first input
			var elem = (this.type == 'radio') ? $('input[name=' + this.name + ']:first', that.form).get(0) : this;

			var result = that.checkControl(elem);
			if (result === true) {
				that.removeErrorMessage(elem);
			}
			else if (result) {
				that.addErrorMessage(elem, result);
				validationError = true;
			}
		});
		return !validationError;
	},

	/**
	 * @return  mixed   true or error message
	 */
	checkControl: function (el, rules) {
		var noError = true;

		if (rules || $(el).data('nette-rules')) {
			rules = rules || this.getElementRules(el);
			var that = this;
			var ruleName, userRule, arg, isCondition, result, negRule;

			// check the rules
			if (rules && rules.length) {

				$.each(rules, function (key, rule) {

					ruleName = rule.op.replace(/^.*:(.*)$/, '$1');
					negRule = rule.op.match(/^~:.*$/);
					isCondition = !!rule.rules;
					arg = rule.arg || null;
					elem = rule.control ? that.form[rule.control] : el;
					userRule = $(elem).data('validator');
					val = that.getElementValue(elem);

					var success = true;
					if (ruleName && ruleName in that.validators) {
						success = that.validators[ruleName](val, that.form, arg);
					}
					if (userRule && userRule in window) {
						success = window[userRule](val, that.form, arg);
					}
					if (negRule) {
						success = !success;
					}

					if (!success && !isCondition) {
						noError = rule.msg;
					}
					else if (success && isCondition) {
						noError = that.checkControl(el, rule.rules);
					}
				});
			}
		}
		return noError;
	},

	getElementValue: function (elem) {
		var val = $(elem).val();
		// radio
		if (!elem.name || elem.type == 'radio') {
			var name = elem.name ? elem.name : elem[0].name;
			var checkedRadio = $('input[name=' + name + ']:checked');
			val = (checkedRadio.length) ? $(checkedRadio).val() : '';
		}
		else if (elem.type == 'checkbox') {
			// it can be checkboxList
			val = !!$('input[name="' + elem.name + '"]:checked').length;
		}

		if (val === $(elem).data('nette-empty-value')) {
			val = '';
		}
		return val;
	},

	getElementRules: function (el) {
        return (new Function('return ' + $(el).attr('data-nette-rules')))();
	},

	addErrorMessage: function (el, msg) {
		this.removeErrorMessage(el);
		$(el).closest('div.form-group').addClass(this.trErrorClassName);
		var html = '<ul class="' + this.errorSelector.split('.').pop() + '"><li>' + msg + '</li></ul>';
		$(el).closest('div.form-item').append(html);
		this.showGlobalErrorMessage();
	},

	removeErrorMessage: function (el) {
		$(el).closest('div.form-group, div.captcha').removeClass(this.trErrorClassName).find(this.errorSelector).remove();
		this.showGlobalErrorMessage();
	},

	showGlobalErrorMessage: function () {
		if ($(this.form).data('submitted')) {
			var formDiv = $(this.form).closest(this.formDivSelector);
			if (formDiv.find(this.errorSelector).length && this.form[this.globalErrorInput]) {
				formDiv.find(this.globalNoErrorSelector)
					.data('oldhtml', $(this.globalNoErrorSelector).html())
					.removeClass(this.globalNoErrorClassName)
					.addClass(this.globalErrorClassName)
					.append(this.form[this.globalErrorInput].value);
			}
			else {
				formDiv.find(this.globalErrorSelector)
					.html($(this.globalErrorSelector).data('oldhtml'))
					.removeClass(this.globalErrorClassName)
					.addClass(this.globalNoErrorClassName);
			}
		}
	},

	validators: {
		'filled': function (val) {
			return val !== '' && val !== false && val !== null;
		},

		'email': function (val) {
			return (/^("([ !\x23-\x5B\x5D-\x7E]*|\\[ -~])+"|[-a-z0-9!#$%&'*+\/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+\/=?^_`{|}~]+)*)@([0-9a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)+[a-z\u00C0-\u02FF\u0370-\u1EFF][-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF]$/i).test(val);
		},

		'url': function (val) {
			return (/^(https?:\/\/|(?=.*\.))([0-9a-z\u00C0-\u02FF\u0370-\u1EFF](([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)*[a-z\u00C0-\u02FF\u0370-\u1EFF][-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF]|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:\d{1,5})?(\/\S*)?$/i).test(val);
		},

		'regexp': function (val, form, arg) {
			var parts = typeof arg === 'string' ? arg.match(/^\/(.*)\/([imu]*)$/) : false;
			if (parts) {
				try {
					return (new RegExp(parts[1], parts[2].replace('u', ''))).test(val);
				} catch (e) {
				}
			}
		},

		'pattern': function (val, form, arg) {
			try {
				return typeof arg === 'string' ? (new RegExp('^(' + arg + ')$')).test(val) : null;
			} catch (e) {
			}
		},

		'integer': function (val) {
			return (/^-?[0-9]+$/).test(val);
		},

		'float': function (val) {
			return (/^-?[0-9]*[.,]?[0-9]+$/).test(val);
		},

		'range': function (val, form, arg) {
			return $.isArray(arg) ?
				((arg[0] === null || parseFloat(val) >= arg[0]) && (arg[1] === null || parseFloat(val) <= arg[1])) : null;
		},

		'minLength': function (val, form, arg) {
			return val.length >= arg;
		},

		'maxLength': function (val, form, arg) {
			return val.length <= arg;
		},

		'length': function (val, form, arg) {
			arg = $.isArray(arg) ? arg : [arg, arg];
			return (arg[0] === null || val.length >= arg[0]) && (arg[1] === null || val.length <= arg[1]);
		},

		'equal': function (val, form, arg) {
			if (arg === undefined) {
				return false;
			}
			arg = $.isArray(arg) ? arg : [arg];
			for (var i = 0, len = arg.length; i < len; i++) {
				if (val == (arg[i].control ? $(form.elements[arg[i].control]).val() : arg[i])) {
					return true;
				}
			}
			return false;
		}
	},

	/*addValidator: function(name, func)
	 {
	 this.validators[name] = func;
	 },*/

	ajaxSubmit: function () {
		var form = this.form,
			that = this,
			ajaxHandler = (this.ajaxHandlerInput in form && form[this.ajaxHandlerInput].value in window) ? window[form[this.ajaxHandlerInput].value] : null,
			values = new FormData(this.form),
			options = {
				url:  $(form).attr('action') ? 'https://' + avastGlobals.web.domain + '/' + $(form).attr('action') : document.location.href,
				cache: false,
				data: new URLSearchParams(values),
				dataFilter: function (data) {
					return $.trim(data);
				},
				dataType: 'json',
				type: $(form).attr('method') || 'get'
			};

		var submit = $('input[type=submit].ajax');
		if (submit.length && !$('img.form-ajax-loader').length) {
			submit.hide().after('<img src="//static3.avast.com/web/i/ajax-loader.gif" alt="" class="form-ajax-loader" />');
		}

		fetch(options.url + '?projectId[]=29047&projectId[]=28382', {
			headers: {
				'Accept': 'application/json',
				'X-Requested-With': 'XMLHttpRequest'
			},
			method: options.type,
			body: options.data

		}).then(function (response) {
			return response.json();

		}).then(function (data) {
			if (typeof dataLayer !== 'undefined' && dataLayer.length > 0) {
				// There may be script which pushes to dataLayer 'formCompleted'. Timeout set to wait for final push.
				setTimeout(function() {
					for (var i = 0; i < dataLayer.length; i++) {
						if (typeof dataLayer[i].event !== 'undefined' && dataLayer[i].event === 'formCompleted') {
							return;
						}
					}
					dataLayer.push({'event': 'formCompleted'});
				}, 10);
			}
			$(document).trigger('avastform-linear-success');
			$('input[type=submit].ajax').show();
			$('img.form-ajax-loader').remove();
			ajaxHandler ? ajaxHandler(data, form) : that.processResult(data);
		}).catch(function (err) {
			$('img.form-ajax-loader').remove();
			$('input[type=submit].ajax').show();
		});

		return false;
	},

	/**
	 * default ajax handler
	 */
	processResult: function (data) {
		if ($.isPlainObject(data)) {

			if ('errors' in data) {
				for (var name in data.errors) {
					if (name in this.form) {
						this.addErrorMessage(this.form[name], data.errors[name]);
					}
				}
			}

			if ('customErrors' in data && $('.formCustomErrorMessage').length > 0) {
				$('.formCustomErrorMessage').html(data.customErrors).removeClass('hidden');
			}

			if ('replaces' in data) {
				for (var replace in data.replaces) {
					// convert entities to html if needed
					if (data.replaces[replace].indexOf('&lt;') > -1) {
						var html = $('<div/>').html(data.replaces[replace]).text();
					} else {
						var html = data.replaces[replace];
					}
					if (replace == 'form') {
						$(this.form).replaceWith(html);
					}
					else if (replace.indexOf('#') === 0) {
						$(replace).replaceWith(html);
					}
				}
			}

		}
	},

	/**
	 * adds help icons next to inputs
	 *
	 * @param   object  el      element with help text
	 */
	addHelp: function (el) {
		var help = $(el).data('help'),
			parEl = $(el).parent();
		if (help) {
			if (!$(this.helpDivSelector, parEl).length) {
				$(el).after('<span class="tooltip-w tooltip-i" vocw="_help' + el.name + '"></span>' +
					'<div id="_help' + el.name + '" class="hidden" style="display:none;">' +
					'<div class="bundle-popup"><div class="bundle-popup-content">' + help + '</div></div></div>');
			}
		}
	},

	hideConditionInputs: function () {
        var that = this;

        $('input[data-display-condition][data-nette-rules]', this.form).each(function () {

			var el = this;
			var rules = that.getElementRules(el);

			$.each(rules, function (key, rule) {
				var selector = that.form[rule.control];
				// radio
				if ($.isArray(selector)) {
					selector = $('input[name=' + selector[0].name + ']', that.form);
				}
				that.checkConditionInput(selector, rule, el);
				$(selector).on('change keyup click', function () {
					that.checkConditionInput(this, rule, el);
				});
			});
        });
	},

	checkConditionInput: function (checkEl, rules, showEl) {
		rules.rules = null;
		rules.control = null;
		if (this.checkControl(checkEl, [rules]) === true) {
			$(showEl).closest('div.form-group').show();
		}
		else {
			$(showEl).closest('div.form-group').hide();
			this.removeErrorMessage(showEl);
		}
	},


	/*
	 * Method will update elements values form given data from ajax
	 * @method ajaxUpdate
	 * @param object thisEl - current element properties - attribute [data-ajax] needed
	 * @return boolean
	 */
	ajaxUpdate: function (thisEl) {
		//fix for chars like ''
		var definitions = thisEl
			.attr('data-ajax')
			.replace(/\'/g, "\"");

		var thisVal = thisEl.val();

		//read and instance definition object
		var def = $.parseJSON(definitions);

		//check if necessary params exists in given definition object
		if (typeof def.destEl === 'undefined' && ( typeof def.url === 'undefined' || typeof def.condition === 'undefined' )) {
			return false;
		}


		/*
		 * Function will update html tag from values form given data from ajax
		 * @function tagUpdate
		 * @param object data - object wit data from ajax or ajaxResponseStorage
		 * @param string destEl - destination element
		 * @return boolean
		 */
		function _tagUpdate(data, destEl) {
			//set working element
			var el = $('form.avast-form [name=' + destEl + ']');

			switch ($(el).prop('tagName').toLowerCase()) {
				case 'select':
					var firstEl = $(el).find('option:first');
					$(el)
						.empty()
						.append(firstEl);
					//fill select
					$.each(data, function (key, val) {
						$(el).append("<option value='" + val.elVal + "'>" + val.elName + "</option>");
					});
					break;
				case 'input':
					$(el)
						.empty()
						.val(data.elVal)
						.text(data.elText);
					break;
				case 'textarea':
					$(el)
						.empty()
						.text(data.elText);
					break;
			}
		}

		var url = typeof def.url === 'undefined' ? false : def.url;

		//Process update
		if (typeof def.condition === 'undefined') {
			//execure ajax
			if (url) {
				AvastFormAjax.doAjax(url, _tagUpdate, false, def.destEl);
			}
		}
		else {
			//apply conditions and check if all ok
			$.each(def.condition, function (key, val) {
				//test condition
				if (thisEl.val() === val.id) {
					//select correct URL
					var u = typeof val.url === 'undefined' ? url : val.url;
					//execure ajax
					AvastFormAjax.doAjax(u, _tagUpdate, false, val.destEl);
				}
			});
		}

	}

};


/*
 * AvastForm Ajax provide functionality for getting data from ajax call and store it in to array
 * @class AvastForm.ajax
 */
var AvastFormAjax = {
	ajaxResponseStorage: [],
	ajaxExpirationDelay: 60000, //miliseconds

	/*
	 * Method will do ajax call on given URL when previous response not exists in ajaxResponseStorage object or the record expired in ajaxResponseStorage
	 * @function doAjax
	 * @param string url - url to call
	 * @param function _onSuccess - function that will be executed after successed ajax
	 * @param function _onError - function that will be executed on error
	 * @param object respOptions - options that will be delivered to final functions (on succes, etc)
	 * @return boolean
	 */
	doAjax: function (url, _onSuccess, _onError, respOptions) {
		//make key from url to store data properly in ajaxResponseStorage
		var key = AvastFormAjax.makeKeyFromUrl(url);

		if (AvastFormAjax.isExpired(key)) {
			//do ajax for data
			$.ajax({
				url: encodeURI(url),
				success: function (data) {
					//write to storage
					AvastFormAjax.writeToStorage(key, data);

					if (typeof _onSuccess === 'function') {
						_onSuccess(data, respOptions);
					}
				},
				error: function (data) {
					if (typeof _onError === 'function') {
						_onError(data, respOptions);
					}
				}
			});
		}
		else {
			_onSuccess(AvastFormAjax.readFromStorage(key), respOptions);
		}
	},


	/*
	 * Method will create key from given url
	 * @function makeKeyFromUrl
	 * @param string url - url to change to key
	 * @return string
	 */
	makeKeyFromUrl: function (url) {
		return url
			.replace(/\//gi, "-")
			.replace(/\&/gi, "----")
			.replace(/\=/gi, "---")
			.replace(/\?/gi, "--")
			.replace(/\%/gi, "-----")
			.replace(/\;/gi, "------")
			.replace(/\:/gi, "-");
	},


	/*
	 * Method will write data to ajaxResponseStorage
	 * @function writeToStorage
	 * @param string key - key in ajaxResponseStorage
	 * @param object data - data to write
	 * @param int expire - expiration timestamp
	 * @return boolean
	 */
	writeToStorage: function (key, data, expire) {
		var curr = new Date().getTime();

		AvastFormAjax.ajaxResponseStorage[ key ] = {
			expire: curr + (typeof expire !== 'undefined' ? expire : AvastFormAjax.ajaxExpirationDelay),
			data: data
		};
	},


	/*
	 * Method will read data from ajaxResponseStorage
	 * @function writeToStorage
	 * @param string key - key in ajaxResponseStorage
	 * @return object
	 */
	readFromStorage: function (key) {
		if (typeof AvastFormAjax.ajaxResponseStorage[ key ] !== 'undefined' && typeof AvastFormAjax.ajaxResponseStorage[ key ].data !== 'undefined') {
			return AvastFormAjax.ajaxResponseStorage[ key ].data;
		}
		else {
			return false;
		}
	},

	/*
	 * Method will check if the key in ajaxResponseStorage expired
	 * @function writeToStorage
	 * @param string key - key in ajaxResponseStorage
	 * @return object
	 */
	isExpired: function (key) {
		var curr = new Date().getTime();

		//check if key exists in ajaxResponseStorage
		if (typeof AvastFormAjax.ajaxResponseStorage[ key ] !== 'undefined') {
			//check if record expired
			if (AvastFormAjax.ajaxResponseStorage[ key ].expire < curr) {
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return true;
		}
	}

};


/**
 * success form submit tracking
 *
 * @param   string  formName
 */
function avastFormSubmitTracking(formName) {}

/**
 * form - start of filling tracking
 *
 * @param   string  formName
 */
function avastFormStartTracking(formName) {}
